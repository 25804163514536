
import React, { useState, useContext, useEffect } from "react";
import { ArrowNarrowLeftIcon, LockClosedIcon, MailIcon, PhoneIcon, UserIcon, ThumbUpIcon } from "@heroicons/react/solid";
import { Col, Row, Form, Card, Button, FormCheck, Container, Image, InputGroup } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {useForm, Controller} from "react-hook-form";
import { Routes } from "routes";
import { preOrderformOptions, codeFormOptions } from "components/validator/validator";
import FormErrors from "components/validator/FormErrors";
import logo from "assets/img/brand/harvast-logo.svg";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { UserContext } from "Context/UserContext";
import "react-phone-number-input/style.css";
import GooglePlayImage from "assets/img/icons/google-play.png";
import AppleStoreImage from "assets/img/icons/apple-store.png";

export default () => {
  const [loggedUser, setLoggedUser] = useContext(UserContext);
  const [phoneinfo, setphoneinfo] = useState('');
  const [cognitoerror, setcognitoError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeForm, setactiveForm] = useState("step1");
  const [codeStatus, setCodeStatus] = useState('');
  const [success, setSucesss] = useState(false);
  const [passwordInfo, setPasswordInfo] = useState('');
  const [hasTeam, setHasTeam] = useState("no");
  const [preRegisterConfirmation, setPreRegisterConfirmation] = useState("no");

  const { 
    register, 
    handleSubmit, 
    reset, 
    control,
    formState: { errors } 
  } = useForm(preOrderformOptions);

  const { 
    register:register1, 
    handleSubmit:handleSubmit1, 
    reset:reset1,
    formState: { errors: errors1 }  
  } = useForm(codeFormOptions);  

  const AUTH_USER_TOKEN_KEY = "";
  const history = useHistory();
  
  function handleTeamCheckBox(e)
  {
    setHasTeam(e.target.value);
  }

  function handlePreRegisterCheckBox(e){
    setPreRegisterConfirmation(e.target.value);
  }

  function preRegistrationLogic(){
    console.log("has team ",hasTeam);
    console.log("pre registration confirmation ", preRegisterConfirmation)
    // check if user has team and pre-register confirmation is yes
    if(hasTeam === "yes" && preRegisterConfirmation === "yes"){
      setLoading(true);
      // loadStripe();
      window.location.replace('https://buy.stripe.com/bIY9AQdYq2fy8Pm8wy');
    }else{
      
      // navigate to download app page
      setactiveForm("step3");
    }
  
  }

  // get agent info
  var PreRegisterAgentData = JSON.parse(sessionStorage.getItem("PreRegisterAgent"));
  // call stripe page
  const loadStripe = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/checkout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Accept': "application/json"
      },
      // Send along all the information about the items
      body: JSON.stringify(
        {
          "type": "agency",
          "firstName": PreRegisterAgentData.firstName,
          "lastName": PreRegisterAgentData.lastName,
          "phone": PreRegisterAgentData.phone,
          "quantity": 1,
          "successUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/",
          "cancelUrl": "https://master.dmu5xszxbulza.amplifyapp.com/#/pre-order/register"
        }
      ),
    })
      .then(res => {
        if (res.ok) return res.json()
        // If there is an error then make sure we catch that
        return res.json().then(e => Promise.reject(e))
      })
      .then(({ url }) => {
        // On success redirect the customer to the returned URL
        window.location = url
        setLoading(false);
      })
      .catch(e => {
        console.error(e.error)
        setLoading(false);
      })
  };
 

   // preOrder register
   async function preRegister(data) {
        // bypasslogic for testing purposes
        // setactiveForm("step2");
        // return true;
        let lowercase_email = data.email.toLowerCase();
        setLoading(true);
        sessionStorage.removeItem('PreRegisterAgent');
        sessionStorage.setItem('PreRegisterAgent', JSON.stringify({
            "firstName": data.first_name,
            "lastName": data.last_name,
            "phone": data.phone,
            "email": lowercase_email,
        }));  
        try {
            await fetch(`${process.env.REACT_APP_BASE_URL}/agent`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                'Accept': 'application/json',
            },
            body: JSON.stringify(
                {
                "firstName": data.first_name,
                "lastName": data.last_name,
                "phone": data.phone,
                "email": lowercase_email,
                "accountType": "invitee"
                }),
            })
            .then(res => {
                if (res.ok) return res.json()
                
                // If there is an error then make sure we catch that
                return res.json().then(e => Promise.reject(e))
            }).then(({ agent, message }) => {
              console.log(agent);
              setactiveForm("step2");
              setLoading(false);
            })
        } catch (error) {
            console.log(error);
            setactiveForm("step2");
            setLoading(false);
        }
    }


  // Check subsripttion status
//   async function getSubscriptions() {
//     let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
//     let phoneData = phoneinfo.substring(1);
//     try {
//       await fetch(`${process.env.REACT_APP_BASE_URL}/subscriptions?phone=%2B${phoneData}`, {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//             'Accept': 'application/json',
//             'Authorization': `Bearer ${token}`
//           },
//           body: JSON.stringify(),
//         })
//         .then(res => {
//           if (res.ok) return res.json()
//           // If there is an error then make sure we catch that
//           return res.json().then(
//             e => Promise.reject(e)
//           )
//         }).then(({subscription}) => {
//           setLoggedUser({
//             ...loggedUser,
//             licenseDetails: subscription
//           })

//           if(subscription.allotedSeats > 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
//             history.push(Routes.DashboardOverview.path);
//           } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && !subscription.licenseType.includes("free")){
//             history.push(Routes.DashboardOverview.path);
//           } else if((subscription.allotedSeats == 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) || (subscription.allotedSeats > 1 && subscription.subscriptionStatus.toLowerCase().includes("cancelled")) ){
//             history.push(Routes.Cancelled.path);
//           } else if (subscription.allotedSeats == 1 && !subscription.subscriptionStatus.toLowerCase().includes("cancelled") && subscription.licenseType.includes("free")){
//             history.push(Routes.Welcome.path);
//           } else{
//             history.push(Routes.Welcome.path);
//           }
//           setSucesss(true);
//           setLoading(false);
//         })
//       } catch (error) {
//         console.log(error)
//         setSucesss(true);
//         setLoading(false);
//         history.push(Routes.Transactions.path);
//       }
//     }

  return (
    <main>
      <section className="vh-lg-100 mt-5 mt-lg-0 bg-soft d-flex align-items-center overflow-auto">
        <Container>
         
          <div className="justify-content-center form-bg-image">
            <div className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-720">
                <div className="text-center text-md-center mb-2 mt-md-0">
                    <Image fluid rounded src={logo} className="logo-img"  />
                </div>
               

                {
                  activeForm === "step1" && <Form className="mt-4" onSubmit={handleSubmit(preRegister)}> 
                  <Row>
                    <div className="text-left mb-4 mt-md-0 pt-3">
                      <h3 className="mb-0">Harvast Registration</h3>
                      <p className="mb-0">Sign Up for Harvast's Teams feature</p>
                    </div>
                  </Row>
                  <Row>
                    <Col md={6} className="mb-4">
                      <Form.Group id="first_name">
                        <Form.Label>First Name</Form.Label>

                        {errors.first_name && <label className="text-danger d-block">{errors.first_name?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <UserIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="text"
                            name="first_name"
                            {...register("first_name")}
                            placeholder="First Name"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group id="last_name">
                        <Form.Label>Last Name</Form.Label>

                        {errors.last_name && <label className="text-danger d-block">{errors.last_name?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <UserIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="text"
                            name="last_name"
                            {...register("last_name")}
                            placeholder="Last Name"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group id="email">
                        <Form.Label>Email</Form.Label>

                        {errors.email && <label className="text-danger d-block">{errors.email?.message}</label>}

                        <InputGroup>
                          <InputGroup.Text>
                            <MailIcon className="icon icon-xs text-gray-600" />
                          </InputGroup.Text>
                          <Form.Control
                            autoFocus
                            type="email"
                            name="email"
                            {...register("email")}
                            placeholder="example@company.com"
                          />
                        </InputGroup>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group id="phone">
                        <Form.Label>Phone Number</Form.Label>

                        {errors.phone && <label className="text-danger d-block">{errors.phone?.message}</label>}

                        <InputGroup>
                          <Controller
                            name="phone"
                            control={control}
                            rules={{
                            validate: (value) => isValidPhoneNumber(value)
                            }}
                            render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              value={value}
                              placeholder="Phone Number"
                              onChange={onChange}
                              defaultCountry="CA"
                              id="phone-input"
                            />
                            )}
                          />
                        
                        </InputGroup>
                      </Form.Group>
                    </Col>

                    <Col md={12}>
                      <FormErrors formerrors={cognitoerror} />
                    </Col>

                    </Row>

                    <div className="d-grid">
                      
                      {
                      loading ? 
                      <Button variant="gray-800" type="button" disabled>
                          <span className="sr-only">Loading...</span>
                          <span className="loading-spacer"></span>
                          <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                      </Button>
                       : 
                       <Button variant="gray-800" type="submit" >
                        Next
                       </Button>
                      }

                    </div>

                  </Form>
                }

                {
                    activeForm === "step2" && 
                    <Form className="mt-4">
                         <fieldset>
                          <div className="text-left mb-4 mt-md-0 pt-3">
                            <p className="mb-0">Step 2 of 3</p>
                          </div>
                            <Row>
                                <Form.Label>Do you have a Team?</Form.Label>
                                <Col md={6} className="mb-4" >
                                    <Form.Check type="radio"  label="Yes" value="yes" onChange={handleTeamCheckBox}
                                    name="teamRadioBtn" id="haveTeam" htmlFor="haveTeam"/>
                                </Col>
                                <Col md={6} className="mb-4"> 
                                <Form.Check type="radio"  value="no" onChange={handleTeamCheckBox}
                                    label="No" name="teamRadioBtn" id="radio2" htmlFor="radio2" />
                                </Col>
                            </Row>
                            {
                              hasTeam === "yes" && 
                                <Row>
                                  <Form.Label>Would you like to pre-register for our Harvast Teams add-on?</Form.Label>
                                  <Col md={6} className="mb-4" >
                                      <Form.Check type="radio"  label="Yes" value="yes" onChange={handlePreRegisterCheckBox}
                                      name="preRegisterRadio" id="haveTeam" htmlFor="haveTeam"/>
                                  </Col>
                                  <Col md={6} className="mb-4"> 
                                  <Form.Check type="radio"  value="no" onChange={handlePreRegisterCheckBox}
                                      label="No" name="preRegisterRadio" id="radio2" htmlFor="radio2" />
                                  </Col>
                              </Row>
                            }
                            
                            <Row>
                              {
                                loading ? 
                                <Button variant="gray-800" type="button" disabled>
                                    <span className="sr-only">Loading...</span>
                                    <span className="loading-spacer"></span>
                                    <span className="spinner-border spinner-border-sm float-right" role="status" aria-hidden="true"></span>
                                </Button>
                                 : 
                                  <Button variant="gray-800" type="button" onClick={preRegistrationLogic}>
                                    Next
                                </Button>
                              }
                             
                            </Row>
                         </fieldset>
                  </Form>
                }
                {
                    activeForm === "step3" && 
                    <Form className="mt-4">
                         <fieldset>
                          <div className="text-left mb-4 mt-md-0 pt-3">
                            <h3 className="text-center">Welcome </h3>
                            <p className="mb-0">Step 3 of 3</p>
                          </div>
                           
                          <div className="d-flex align-items-center mb-4 mx-auto justify-content-center">
                                <a className="d-block app-icon app-icon-left" href="https://play.google.com/store/apps/details?id=com.wocketship.harvast.android" target="_blank">
                                <Image src={GooglePlayImage} className="img-fluid" />
                              </a>
                              <a className="d-block app-icon app-icon-right" href="https://apps.apple.com/ca/app/harvast/id1644409949" target="_blank">
                                <Image src={AppleStoreImage} className="img-fluid" />
                              </a>
                          </div>
                            
                        <Row>
                        <a href="https://harvast.com"  className="d-flex align-items-center justify-content-center">
                            Back to Harvast.com
                          </a>
                        </Row>
                      </fieldset>
                  </Form>
                }

              </div>
            </div>
          </div>
        </Container>
      </section>
    </main>
  );
};
