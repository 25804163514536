export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  Signin: { path: "/Auth/Signin" },
  Presentation: { path: "/Presentation" },
  DashboardAgencies: { path: "/dashboard/agencies-dashboard" },
  DashboardTraffic: { path: "/dashboard/traffic-and-engagement" },
  DashboardProductAnalysis: { path: "/dashboard/product-analysis" },
  Welcome: { path: "/welcome" },
  AgentDashboard: { path: "/agent/Stats" },
  Kanban: { path: "/kanban" },
  Messages: { path: "/messages" },
  SingleMessage: { path: "/message" },
  Users: { path: "/users" },
  CreateAgent: { path: "/CreateAgent" },
  CreateAgencyAgent: { path: "/CreateAgencyAgent" },
  CreateTeam: { path: "/Agency/CreateTeam" },
  Transactions: { path: "/transactions" },
  PaymentSuccesful: { path: "/PaymentSuccesful" },
  CreateAgency: { path: "/CreateAgency" },
  ManageTeam: { path: "/ManageTeam" },
  Cancelled: { path: "/Cancelled" },
  Tasks: { path: "/tasks" },
  Settings: { path: "/settings" },
  Calendar: { path: "/calendar" },
  Map: { path: "/map" },
  Datatables: { path: "/tables/datatables" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Pricing: { path: "/examples/pricing" },
  Billing: { path: "/examples/billing" },
  Invoice: { path: "/examples/invoice" },
  //Signup: { path: "/Auth/sign-up" },
  PreOrder: { path: "/pre-order/register" },
  ForgotPassword: { path: "/Auth/forgot-password" },
  GetCode: { path: "/Auth/GetCode" },
  SendCode: { path: "/Auth/SendCode" },
  ResetPassword: { path: "/Auth/reset-password" },
  Lock: { path: "/examples/lock" },
  NotFound: { path: "/examples/404" },
  ServerError: { path: "/examples/500" },

  // docs
  DocsOverview: { path: "/documentation/overview" },
  DocsDownload: { path: "/documentation/download" },
  DocsQuickStart: { path: "/documentation/quick-start" },
  DocsLicense: { path: "/documentation/license" },
  DocsFolderStructure: { path: "/documentation/folder-structure" },
  DocsBuild: { path: "/documentation/build-tools" },
  DocsChangelog: { path: "/documentation/changelog" },

  // plugins
  PluginCharts: { path: "/plugins/charts" },
  PluginCalendar: { path: "/plugins/calendar" },
  PluginDatatable: { path: "/plugins/datatable" },
  PluginMap: { path: "/plugins/map" },
  PluginDropzone: { path: "/plugins/dropzone" },
  PluginSweetAlert: { path: "/plugins/sweet-alert" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};
